import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from './components/Layout.js';
import './App.css';
import ReactGA from 'react-ga';

class App extends Component {
  render() {
    ReactGA.initialize("G-B0EEE8LPZG");
    return (
      <React.Fragment>
        <CssBaseline />
        <Layout />
      </React.Fragment>
    );
  }
}

export default App;

import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    width: "100vw",
    maxWidth: "100vw !important"
  },
}));

export default function InputTab(props) {
  const classes = useStyles();
  const [query, setQuery] = useState("")

  const handleChange = (event) => {
    setQuery(event.target.value );
  };

  const onClick = () => {
    props.onClick(query);
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
    <Box sx={{width: "100%", fontFamily: "Courier New"}}>

    <TextField
            id="outlined-textarea"
            label="Enter Gremlin Query"
            multiline
            rows={10}
            onChange={handleChange}
            style={{width:"100%", fontFamily: "Courier New", fontSize:"small", backgroundColor: "white"}}
          />
          <br />
          <Button variant="contained" disabled={query===""}
            style={{margin: 10}} onClick={onClick}>Translate</Button>     
            </Box>
  </Container>
  );
}
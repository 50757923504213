import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    width: "100vw",
    maxWidth: "100vw !important"
  },
}));

export default function ResponseTab(props) {

  return (
      <div style={{padding: "0", marginTop: "-25px"}}>
        {props.text && 
        <Stack>
            <Item>{props.text}</Item>
            <Button variant="text" onClick={() => {navigator.clipboard.writeText(props.text)}}>
            Copy To Clipboard</Button>
        </Stack>
        }
    </div>
  );
}
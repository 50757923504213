import React, {useState} from "react";
import axios from "axios";
import { makeStyles } from '@mui/styles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputTab from './InputTab';
import configData from '../config.json';
import logo from '../img/gremlin-apache.png'
import ResponseTabs from './ResponseTabs';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';


const drawerWidth = 500;
const baseURL = configData['SERVER_URL'];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Amazon Ember, Roboto, Helvetica",
    backgroundColor: "#eeeeee"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#232F3E",
    textShadow: "0 1px 0 #000",
    color: "#eee",
    fontSize: "15px",
    fontWeight: "bold",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },
  title: {
    flexGrow: 1,
    color: "#f2f2f2",
    fontFamily: "Amazon Ember",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    paddingTop: 75
  },
  drawer: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
    width: drawerWidth,
  },
  container: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  paper: {
    padding: 2,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  chatbot: {
    height: "100vh",
  },
}));

/**
 * The main function of the application
 */
export default function Layout() {
  const classes = useStyles();
  const [translation, setTranslation] = useState({python:"",dotNet:"",groovy:"",javascript:""})
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const handleTranslate = (query) => {
    console.log('Translate:' + query);
    axios.post(baseURL, {query: query},
    {headers: {
      'content-type': 'application/json'
    }})
    .then(response => {
      setTranslation(response.data);
      if (response.data.exceptionMessage) {
          setAlertMessage(response.data.message + "/n" + response.data.exceptionMessage)
      } else {
        setAlertMessage("");
      }
      setAlertOpen(false)
    }).catch(err => {
      setTranslation({python:"",dotNet:"",groovy:"",javascript:""});
      setAlertMessage("An error occurred when translating your query, this is frequently caused by an invalid query. Please check the query and try again.");
      setAlertOpen(true)
    });
  }


  return (
    <div className={classes.root}>
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
        <img src={logo} alt="Apache Gremlin logo" style={{height:50, width:75}}/>
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Gremlator
            <Typography
              variant="caption"
              component="div"
              color="inherit"
              noWrap
            > Powered by &nbsp;
            <Link href="https://tinkerpop.apache.org/" variant="caption" target="_blank">
               Apache TinkerPop
            </Link>
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            component="div"
            color="inherit"
            noWrap
          >
            Gremlin Language Variant Translator
            <Typography
              variant="caption"
              component="div"
              color="inherit"
              noWrap
            > 
            TinkerPop Version 3.6.2
            </Typography>

          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
      <Collapse in={alertOpen}>
      <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
        </Collapse>
        <div>
          <InputTab onClick={handleTranslate} />
        </div>
        <div>
          <ResponseTabs results={translation} />
        </div>
      </main>
    </div>
  );
}
